import { Component, Inject, Vue } from 'vue-property-decorator';
import { ISpace } from '@/shared/model/space.model';
import SpaceService from '@/entities/space/space.service';
import axios from 'axios';

export interface StoreSpace {
  id: string;
  name: string;
}

@Component({})
export default class SelectSpace extends Vue {
  @Inject('spaceService') private spaceService: () => SpaceService;
  public spaces: StoreSpace[] = [];

  public filters: any = {
    selectSpace: '',
  };

  public selectedSpaceId: string = null;
  public storeSpace: StoreSpace = null;

  created(): void {
    this.storeSpace = JSON.parse(sessionStorage.getItem('space')) as StoreSpace;
    if (this.storeSpace != null) {
      // l'espace a été stocké dans la session, on l'ajoute dans le store
      this.selectedSpaceId = this.storeSpace.id;
      this.$store.commit('space', this.storeSpace);
    }
    this.initRelationships();
  }

  public initRelationships(): void {
    axios.defaults.headers.common.Authorization = 'Bearer ${res.accessToken}';
    this.spaceService()
      .retrieveAllowSpaces()
      .then(res => {
        this.spaces = res.data.sort((a, b) => a.name.localeCompare(b.name));

        // On récupère le space depuis l'api qui correspond à l'id ou à defaut le premier de la liste
        if (this.spaces.length > 0) {
          this.storeSpace = this.storeSpace ? this.spaces.find(space => space.id === this.storeSpace.id) || this.spaces[0] : this.spaces[0];
        }
        //On sauvegarde la dernière version venu de l'api dans le store a la session Storage'
        this.selectedSpaceId = this.storeSpace != null ? this.storeSpace.id : null;
        this.$store.commit('space', this.storeSpace);
        sessionStorage.setItem('space', JSON.stringify(this.storeSpace));
      });
  }

  onChange(): void {
    this.storeSpace = this.spaces.find(space => space.id === this.selectedSpaceId);

    this.$store.commit('space', this.storeSpace);
    sessionStorage.setItem('space', JSON.stringify(this.storeSpace));
  }
}
